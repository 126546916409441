<template>
  <div class="main">
    <!-- body start -->
    <div class="textbox" style="padding: 30px 0 0 10px">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/carrier' }"
          >产业载体</el-breadcrumb-item
        >
        <el-breadcrumb-item>载体列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="bodybox" style="padding-top:0">
      <div class="textbox">
        <div>
          <div class="layout-content service-ct">
            <div class="sr-grid has-gap36">
              <div id="filter" class="app-filter">
                <div
                  class="tagbox"
                  :style="iswelfare ? '' : ' max-height: 70px;'"
                >
                  <div class="tagleft" @click="getwelfare">
                    载体类型
                    <i v-if="!iswelfare" class="el-icon-arrow-down"></i>
                    <i v-else class="el-icon-arrow-up"></i>
                  </div>
                  <div class="tagright">
                    <span
                      :class="-1 == indexaa ? 'active' : ''"
                      @click="choeseaa(null, -1)"
                      href="javascript:void(0)"
                      >不限
                    </span>
                    <span
                      v-for="(item, index) in CateList"
                      :key="index"
                      :class="index == indexaa ? 'active' : ''"
                      @click="choeseaa(item, index)"
                      >{{ item.Name }}</span
                    >
                  </div>
                </div>
                     <div
                  class="tagbox"
                  :style="isrecType ? '' : ' max-height: 70px;'"
                >
                  <div class="tagleft" @click="getrecType">
                    载体级别
                    <i v-if="!isrecType" class="el-icon-arrow-down"></i>
                    <i v-else class="el-icon-arrow-up"></i>
                  </div>
                  <div class="tagright">
                    <span
                      :class="-1 == indexbb ? 'active' : ''"
                      @click="choesebb(null, -1)"
                      href="javascript:void(0)"
                      >不限
                    </span>
                    <span
                      v-for="(item, index) in LevelList"
                      :key="index"
                      :class="index == indexbb ? 'active' : ''"
                          @click="choesebb(item, index)"
                      >{{ item.Name }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sr-grid">
            <div class="sswarp"></div>
            <div class="zwwarp">
              <div class="box" v-for="(item, i) in BuildingList" :key="i">
                <div
                  class="box_top"
                  @click="GetBuildingIn(item.SysID)"
                  style="cursor: pointer"
                >
                  <span>{{ item.Name }}</span
                  >[{{ item.CateName }}]
                </div>
                <div class="box_bottom">
                  <div
                    class="bottom_left"
                    @click="GetBuildingIn(item.SysID)"
                    style="cursor: pointer"
                  >
                    <el-image
                      v-if="item.FileList != null && item.FileList.length > 0"
                      :src="item.FileList[0].URL"
                      fit="cover"
                    ></el-image>
                  </div>
                  <div class="bottom_right">
                    <div class="lines">
                      <div class="lines_tab">载体面积：</div>
                      <div class="lines_text" v-if="item.BuildingArea">
                        {{ item.BuildingArea }} m²
                      </div>
                    </div>
                    <div class="lines">
                      <div class="lines_tab">载体级别：</div>
                      <div
                        class="lines_text"
                        v-if="
                          item.BuildingLevel != null &&
                          item.BuildingLevel.indexOf(',') != -1
                        "
                      >
                        <div
                          class="lines_tags"
                          v-for="(tag, t) in item.BuildingLevel.split(',')"
                          :key="t"
                        >
                          {{ tag }}
                        </div>
                      </div>
                      <div class="lines_text" v-else>
                        <div class="lines_tags">
                          {{ item.BuildingLevel }}
                        </div>
                      </div>
                    </div>
                    <div class="lines">
                      <div class="lines_tab">载体地址：</div>
                      <div class="lines_text">{{ item.Address }}</div>
                    </div>
                    <div class="lines">
                      <div class="lines_tab">关注领域：</div>

                      <div
                        class="lines_text"
                        v-if="
                          item.FollowTag != null &&
                          item.FollowTag.indexOf(',') > -1
                        "
                      >
                        <div
                          class="lines_tags"
                          v-for="(tag, t) in item.FollowTag.split(',')"
                          :key="t"
                        >
                          {{ tag }}
                        </div>
                      </div>
                      <div class="lines_text" v-else>
                        <div class="lines_tags">
                          {{ item.FollowTag }}
                        </div>
                      </div>
                    </div>
                    <div class="lines">
                      <div class="lines_tab">入驻企业：</div>
                      <div
                        class="lines_text"
                        style="color: #409eff"
                        v-if="item.CompanyCount"
                      >
                        {{ item.CompanyCount }} 所
                      </div>
                    </div>
                    <div class="lines">
                      <div class="lines_tab">剩余工位：</div>
                      <div
                        class="lines_text"
                        style="color: #409eff"
                        v-if="item.OverplusCount"
                      >
                        {{ item.OverplusCount }} 个
                      </div>
                    </div>
                    <div class="lines">
                      <div class="lines_tab">提供服务：</div>
                      <div
                        class="lines_text"
                        v-if="item.ServeTag.split(',').length"
                      >
                        <div
                          class="lines_tags"
                          v-for="(tag, t) in item.ServeTag.split(',')"
                          :key="t"
                        >
                          {{ tag }}
                        </div>
                      </div>
                      <div class="lines_text" v-else>
                        <div
                          class="lines_tags"
                          v-for="(tag, t) in item.ServeTag.split(',')"
                          :key="t"
                        >
                          {{ item.ServeTag }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="more" v-if="!BuildingList.length">
                <em>暂无数据 </em>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- body end -->
  </div>
</template>

<script>
// 公共样式

import timeFormat from "../util/time.js";
import "../assets/css/common.css";
export default {
  data() {
    return {
      activeIndex: "3",
      drawer: false,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      navlist: [
        {
          names: "首页",
          path: "/",
          id: "1",
        },
        {
          names: "政策资讯",
          path: "/news",
          id: "2",
        },
        {
          names: "产业载体",
          path: "/carrier",
          id: "3",
        },
        {
          names: "企业互动",
          path: "/interaction",
          id: "4",
        },
        {
          names: "金融服务",
          path: "/service",
          id: "5",
        },
        {
          names: "企业服务",
          path: "/enterpriseServices",
          id: "6",
        },
        {
          names: "招聘服务",
          path: "/recruit",
          id: "7",
        },
      ],
      arr: ["zt1", "zt2", "zt3", "zt4", "zt5", "zt6", "zt7", "zt8"],
      aa: ["不限", "众创空间", "孵化器", "大学科技园"],
      indexaa: -1,
      bb: ["不限", "国家级", "省级", "市级", "浐灞新区"],
      indexbb: -1,
      height: "400px",
      input2: "",
      compObj: [],
      CateSysID: "",
      BuildLevel: "",
      CateList: [],
      LevelList: [],
      PageIndex: 1,
      PageSize: 5,
      IsShowMore: 1,
      BuildingList: [],
      iswelfare: false,
      isrecType:false
    };
  },

  watch: {
    windowWidth(val) {
      let that = this;
      // console.log("实时屏幕宽度：", val);
      if (val > 990) {
        that.drawer = false;
        that.height = "400px";
      } else if (val <= 900 && val > 400) {
        that.height = "200px";
      } else {
        that.height = "100px";
      }
    },
  },
  methods: {
    getwelfare() {
      this.iswelfare = !this.iswelfare;
    },
    getrecType(){
this.isrecType = !this.isrecType
    },
    //   企业详情
    GetBuildingIn(id) {
      this.$router.push({ path: "/carrierDetails", query: { SysID: id } });
    },
    GetPageMore() {
      this.PageIndex = this.PageIndex + 1;
      this.GetBuildingList();
    },
    GetCateList() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          State: true,
          Type: "LoadBuild",
          Sort: "Sort asc",
        })
        .then(function (data) {
          console.log(data);
          that.CateList = data.Obj;
        })
        .catch((error) => {
          console.log(error);
        });

      that
        .$post("/api/Dictionary/List", {
          State: true,
          Type: "CarrierLevel",
          Sort: "Sort asc",
        })
        .then(function (data) {
          console.log(data);
          that.LevelList = data.Obj;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetBuildingList() {
      let that = this;
      that
        .$post("/api/Building/List", {
          State: true,
          Sort: "CreateOn desc",
          Category: this.CateSysID,
          BuildLevel: this.BuildLevel,
        })
        .then(function (data) {
          console.log(data);
          that.BuildingList = data.Obj;

          that.BuildingList.forEach((v) => {
            if (v.FileList.length > 0) {
              v.PublishTime = timeFormat.timeFormat(v.PublishTime, 2);
              v.FileList[0].URL = that.urlOA + v.FileList[0].URL.substr(1);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    choeseaa(item, index) {
      console.log(index);
      this.indexaa = index;

      if (item != null) {
        this.CateSysID = item.SysID;
      } else {
        this.CateSysID = "";
      }
      this.BuildingList = [];
      this.GetBuildingList();
    },
    choesebb(item, index) {
      console.log(index);
      this.indexbb = index;

      if (item != null) {
        this.BuildLevel = item.Name;
      } else {
        this.BuildLevel = "";
      }
      this.BuildingList = [];
      this.GetBuildingList();
    },
    handleSelect(e) {
      let that = this;
      console.log(e);
      that.$router.push(e);
      setTimeout(() => {
        that.drawer = false;
      }, 500);
    },
    getlogin() {
      this.$router.push("/login");
    },
    register() {
      this.$router.push("/register");
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  mounted() {
    var that = this;

    that.GetCateList();
    that.GetBuildingList();
    let windowWidth = that.windowWidth;
    console.log(windowWidth);
    if (windowWidth < 900 && windowWidth > 400) {
      that.height = "200px";
    } else if (windowWidth < 400) {
      that.height = "150px";
    }
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight; // 高
        that.windowWidth = window.fullWidth; // 宽
      })();
    };
  },
};
</script>


<style scoped  lang="scss">

.tagbox {
  display: flex;
  border-bottom: 1px dashed #ccc;
  padding: 5px 0;
  .tagleft {
    flex: 1;

    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 700;
    margin-top: 10px;
    overflow: hidden;
    i{
      display: none;
    }
  }
  .tagright {
    flex: 9;
    span {
      float: left;
      margin-right: 30px;
      width: 100px;
      text-align: center;
      padding: 3px 0;
      cursor: pointer;
      font-size: 15px;
      border-radius: 4px;
      margin-top: 10px;
    }
  }
}
.gstb {
  margin-top: 20px;
  float: left;
  width: 50%;
}

.gstb img {
  width: 50px;
}

.gswz {
  flex: 1;
  display: flex;
  color: #888;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
}

.btnBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.gswz img {
  width: 20px;
}

.zplist {
  margin-top: 100px;
  overflow: hidden;
  width: 262px;
  height: 40px;
  border: 1px solid #ccc;
  margin: 30px auto;
  border-radius: 20px;
}

.after,
.before {
  float: left;
  width: 70px;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.after {
  border-right: 1px solid #ccc;
}

.page1 {
  float: left;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-right: 1px solid #ccc;
}

.zaction {
  color: #fff;
  background-color: #3fbcbb;
}

.gwzz {
  margin-top: 20px;
  width: 800px;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0 rgb(105 105 105 / 30%);
}

.gwzz li {
  line-height: 40px;
  font-size: 16px;
}

.gsjj {
  overflow: hidden;
  width: 800px;
  font-size: 16px;
  line-height: 28px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0 rgb(105 105 105 / 30%);
  border-radius: 5px;
  margin-top: 20px;
  padding: 20px;
}

.gsjj .img {
  width: 70px;
  height: 70px;
  border: 1px solid #ccc;
  float: left;
}

.gsjj .name {
  font-size: 20px;
  line-height: 70px;
  float: left;
  margin-left: 20px;
}

.qybq {
  text-align: center;
  width: 100px;
  padding: 5px;
  border: 1px solid #ccc;
  height: 40px;
  line-height: 30px;
  border-radius: 2px;
  font-size: 16px;
  float: left;
  margin-right: 10px;
  margin-top: 10px;
}

.sendjl {
  cursor: pointer;
  margin-top: 110px;
  float: right;
  /* width: 200px; */
  padding: 0 20px;
  height: 40px;
  background-color: #3fbcbb;
  color: #fff;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  border-radius: 4px;
}
.ss {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
}

.zwwarp {
  width: 100%;
  min-height: 400px;
  overflow: hidden;
}

.zwwarp .box {
  margin-top: 20px;
  width: 100%;
  height: 320px;
  background-color: #f3f3f3;
  box-shadow: none;
  box-sizing: border-box;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  .box_top {
    padding: 0 30px;
    height: 60px;
    line-height: 60px;
    font-size: 24px;
    font-weight: 800;
    color: #bd2c00;
    span {
      color: #000;
      cursor: pointer;
    }
    span:hover {
      color: #4b6ff7 !important;
    }
  }
  .box_bottom {
    height: 260px;
    padding: 0 30px;
    box-sizing: border-box;
    //   background-color: #ff3040;
    .bottom_left {
      float: left;
      width: 300px;
      height: 210px;
    }

    .bottom_right {
      float: left;
      height: 100%;
      width: 700px;
      //   overflow: hidden;
      .lines {
        width: 100%;
        line-height: 30px;
        margin-left: 30px;
        display: flex;
        .lines_tab {
          display: inline-block;
          // width: 100px;
          // margin-left: 50px;
          height: 100%;
          line-height: 30px;
          text-align: center;
          font-size: 14px;
          color: #595959;
        }
        .lines_text {
          display: inline-block;
          max-width: 600px;
          // height: 30px;
          padding-left: 10px;
          line-height: 30px;
          text-align: left;
          font-size: 14px;
          color: #595959;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .lines_tags {
          display: inline-block;

          height: 20px;
          padding: 2px 15px;
          border-radius: 4px;
          line-height: 20px;
          text-align: center;
          font-size: 14px;
          color: #000;
          background-color: #ffffff;
          border: 1px solid #ccc;
          margin-right: 10px;
        }
      }
    }
  }
}

.bottom_left::v-deep img {
  width: 300px;
  height: 210px;
}
// 内容区

.textbox {
  width: 1230px;
  margin-left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
}

.aaa {
  display: none;
}

@media (max-width: 991px) {
  .app-filter dl {
    padding: 10px;
    box-sizing: border-box;
  }
  .app-filter dt {
    margin-bottom: 10px;
    width: 100%;
  }
  .app-filter ul li {
    display: inline-block;
    // width: 80px;
    text-align: center;
  }
  .aaa {
    display: block;
  }

  .bannerbox img {
    height: 100%;
  }
  .main .textbox {
    width: 100%;
    overflow: hidden;
    padding-top: 10px;
  }
  .textleft,
  .textright {
    float: left;
    width: 100%;
    overflow: hidden;
    h4 {
      text-align: center;
    }
  }
  .item {
    position: relative;
    height: 120px;
    width: 100%;
    img {
      position: absolute;
      left: 0;
      top: 0;
      height: 120px;
      width: 30%;
    }
    .newsbox {
      position: absolute;
      left: 35%;
      height: 100%;
      width: 60%;
      padding: 6px;
      box-sizing: border-box;
      h3 {
        position: absolute;
        top: 0;
        font-size: 16px;
        height: 40px;
        line-height: 20px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      p {
        position: absolute;
        height: 40px;
        line-height: 20px;
        top: 50px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .itembottom {
        position: absolute;
        height: 20px;
        bottom: 0;
        line-height: 20px;
        font-size: 12px;
      }
    }
  }
}
@media (max-width: 590px) {
  .zwwarp .box {
    width: 96%;
    // padding: 20px;
    height: 270px;
    margin-bottom: 30px;
    box-sizing: border-box;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .gswz {
    display: none;
  }
  .zwwarp .box a {
    flex: 3;
  }
  .btnBox {
    // flex: 2;
    font-size: 12px;
  }

  .box_top {
    padding: 0 10px !important;
    font-size: 18px !important;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .bottom_left {
    float: none;
    position: relative;
    height: 230px;
    width: 350px;
  }
  .box_bottom {
    width: 350px;
    padding: 0 !important;
  }
  .bottom_left::v-deep img {
    width: 400px;
    height: 210px;
  }
  .bottom_right {
    display: none;
  }
    .tagbox {
    display: flex;
    border-bottom: 1px dashed #ccc;
    padding: 20px 0;
   
    overflow: hidden;
    box-sizing: border-box;
    .tagleft {
      flex: 1;
      justify-content: center;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      margin-top: 10px;
      i{
        display: block;
        font-weight: 700;
        margin-left: 4px;
      }
    }
    .tagright {
      flex: 3;
      span {
        float: left;
        margin-right: 10px;
        width: 100px;
        
        text-align: center;
        padding: 3px 0;
        cursor: pointer;
        font-size: 14px;
        border-radius: 4px;
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }
  }
}
</style>